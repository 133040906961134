<script>

//import { DxHtmlEditor } from 'devextreme-vue/html-editor'
import {
    ClassicEditor,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    BlockQuote,
    Bold,
    Base64UploadAdapter,
    CloudServices,
    Code,
    CodeBlock,
    CKBox,
    Essentials,
    FindAndReplace,
    Font,
    GeneralHtmlSupport,
    Heading,
    HorizontalLine,
    HtmlEmbed,
    Image,
    ImageCaption,
    ImageInsert,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    MediaEmbed,
    Mention,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    PictureEditing,
    RemoveFormat,
    ShowBlocks,
    SourceEditing,
    Strikethrough,
    Style,
    Subscript,
    Superscript,     
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TablePropertiesEditing,
    TableToolbar,    
    TableUtils,
    TextPartLanguage,
    TextTransformation,
    TodoList,
    Underline,
    ImageBlock,
    ImageBlockEditing, } from 'ckeditor5';

import { Ckeditor } from '@ckeditor/ckeditor5-vue';

import coreTranslations from 'ckeditor5/translations/pl.js';

import 'ckeditor5/ckeditor5.css';

export default {
    components: { Ckeditor, },
   
    props: {
        
    },
    data() {
        return {  
   
            name: 'editor-parent',
            isLayoutReady: false,
            htmlContent: '',     
            editor: ClassicEditor,
            editorData: '<p>Hello from CKEditor 5 in Vue!</p>',
            editorConfig: null, // CKEditor needs the DOM tree before calculating the configuration.
            
        };
    },

    mounted() {
        
        this.editorConfig = {
                plugins: [
                    ClassicEditor,
                    Alignment,
                    Autoformat,
                    AutoImage,
                    AutoLink,
                    BlockQuote,
                    Bold,
                    Base64UploadAdapter,
                    CloudServices,
                    Code,
                    CodeBlock,
                    CKBox,
                    Essentials,
                    FindAndReplace,
                    Font,
                    GeneralHtmlSupport,
                    Heading,
                    HorizontalLine,
                    HtmlEmbed,
                    Image,
                    ImageCaption,
                    ImageInsert,
                    ImageResize,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    ImageBlock,
                    ImageBlockEditing,                    
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    LinkImage,
                    List,
                    ListProperties,
                    MediaEmbed,
                    Mention,
                    PageBreak,
                    Paragraph,
                    PasteFromOffice,
                    PictureEditing,
                    RemoveFormat,
                    ShowBlocks,
                    SourceEditing,
                    Strikethrough,
                    Style,
                    Subscript,
                    Superscript,
                    Table,
                    TableCaption,
                    TableCellProperties,
                    TableColumnResize,
                    TableProperties,
                    TablePropertiesEditing,
                    TableToolbar,
                    TableUtils,
                    TextPartLanguage,
                    TextTransformation,
                    TodoList,
                    Underline, 
                    ],

                toolbar: ['undo', 'redo',
                            
                            '|',
                            'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                            '|',
                            'bold', 'italic', 'strikethrough',
                            '|',
                            'link', 'uploadImage', 'blockQuote', 'insertTable', 
                            '|',
                            'bulletedList', 'numberedList', 'todoList',
                            '|',
                            'outdent', 'indent', 'alignment',
                            '|',

                         ],
                //licenseKey: 'BNFHPP198.ROY425DAS024',     
                table: {
                        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
                        tableProperties: {
                            // Default styles for the entire table.
                            // to umozliwia domyslne formatowanie tabel, ale styl ponizej musi sie zgadzac z domyslnymi zmianiami
                            defaultProperties: {
                                alignment: 'center',                                
                            }
                    },
                },
                
                translations: [ coreTranslations ],
                           
            };

        this.isLayoutReady = true;            

    },

    computed: {
    },

    methods: {
        
        onValueChange(e) {

            console.log('new value: ', e);


        },

        onPaste(e) {

            console.log('pasted content: ', e);

        },

    },
}

</script>

<template>

<div id="editor-parent">

    <ckeditor
                v-if="isLayoutReady"
                v-model="editorData"                
                :editor="editor"
                :config="editorConfig"                
                @input="onValueChange"
            />

</div>

<!-- <textarea style="margin-top: 150px; width: 100%; min-height: 200px;" v-model="editorData">

</textarea>
 -->

<!-- <div @paste="onPaste" >
    <dxHtmlEditor
            
            :value="htmlContent"
            height="725px"
            @value-changed="onValueChange"
            
        >
        
    </dxHtmlEditor>
</div> -->


</template>

<style>

.ck-content .table {
    float: none;
    margin-left: 0; 
}

.ck-content .table table td,
.ck-content .table table {
    border: none;
}

.ck-content .image {
    float: none;
    margin-left: 0; 
}

</style>